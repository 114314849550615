






























































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  deleteCollaboration,
  getCollaboration,
  saveCollaboration
} from '@/api/collaborations'
import Sticky from '@/components/Sticky/index.vue'
import { Collaboration } from '@/models/Collaboration'
import { UserModule } from '@/store/modules/user'
import { AppModule, DeviceType } from '@/store/modules/app'
import { confirmDialog, errorMsg, getTranslations, successMsg, validateForm } from '@/utils'
import { getRemoteProperties, getRemoteClients } from '@/api/helpers'
import { Form } from 'element-ui'

@Component({
  name: 'CollaborationDetail',
  components: {
    Sticky
  }
})

export default class extends Vue {
  @Prop({ default: false }) private isEdit!: boolean
  private loading = false
  private skeletonLoader = false
  private propertiesLoading = false
  private clientsLoading = false
  private disableClientSelect = false
  private property: any = null
  private getTranslations = getTranslations
  private properties: any[] = []
  private clients: any[] = []
  private collaboration = new Collaboration({
    user_id_from: UserModule.id,
    office_id_from: UserModule.officeId,
    cooperation_date: new Date().getTime()
  })

  private rules = {
    client_id: [
      { required: true, message: this.$t('form.isRequired'), trigger: 'blur' }
    ],
    property_id: [
      { required: true, message: this.$t('form.isRequired'), trigger: 'blur' }
    ]
  }

  created() {
    if (this.isEdit && this.$route.params && this.$route.params.id) {
      this.getItem(this.$route.params.id).then(() => {
        if (this.collaboration.client_id) {
          this.disableClientSelect = true
        }
        if (this.collaboration.property) {
          this.properties = [this.collaboration.property]
        }
        if (this.collaboration.client) {
          this.clients = [this.collaboration.client]
        }
      })
    }
  }

  get isMobile() {
    return AppModule.device === DeviceType.Mobile
  }

  private async getItem(id: string) {
    this.skeletonLoader = true
    try {
      const { data } = await getCollaboration({ id })
      this.collaboration = data
      this.property = this.collaboration.property
    } catch (err) {}
    this.skeletonLoader = false
  }

  private async getRemoteClientsList(query: string) {
    this.clientsLoading = true
    this.clients = await getRemoteClients(query)
    this.clientsLoading = false
  }

  private async getRemotePropertiesList(query: string) {
    this.propertiesLoading = true
    this.properties = await getRemoteProperties(query)
    this.propertiesLoading = false
  }

  private onPropertySelectChange(property: any) {
    if (property) {
      this.collaboration.property_id = property.id
      this.collaboration.office_id_to = property.office_id
      this.collaboration.user_id_to = property.user_id
    } else {
      this.collaboration.property_id = null
      this.collaboration.office_id_to = null
      this.collaboration.user_id_to = null
    }
  }

  private async deleteItem() {
    const [data] = await confirmDialog('actions.apiDelete')
    if (!data) return
    try {
      await deleteCollaboration({
        id: this.collaboration.id
      })
      await successMsg('actions.apiDeleteSuccess')
      return this.$router.push('/collaboration/outgoing-list')
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private prepareCollaboration() {
    this.collaboration.property = null
    this.collaboration.client = null
    this.collaboration.user_from = null
    this.collaboration.user_to = null
    this.collaboration.office_from = null
    this.collaboration.office_to = null
    this.collaboration.cooperation_date = new Date(this.collaboration.cooperation_date).getTime()
  }

  private async submitForm() {
    const [valid] = await validateForm(this.$refs.collaborationForm as Form)
    if (!valid) return errorMsg('form.formErrors')
    this.loading = true
    try {
      this.prepareCollaboration()
      const { data } = await saveCollaboration(this.collaboration)
      if (!this.isEdit) {
        return this.$router.push('/collaboration/edit/' + data.id)
      }
      this.disableClientSelect = true
      await successMsg('actions.apiSuccessSave')
    } catch (err) {
      await errorMsg('api.serverError')
    }
    this.loading = false
  }
}
