/* eslint-disable camelcase */
export class Collaboration {
  id: string | null;
  status: number | null;
  property_id: string | null;
  property: string | null;
  user_id_from: string | null;
  user_from: string | null;
  user_id_to: string | null;
  user_to: string | null;
  office_id_from: string | null;
  office_from: string | null;
  office_id_to: string | null;
  office_to: string | null;
  client_id: string | null;
  client: string | null;
  meta: any;
  cooperation_date: any;

  constructor(data: any = {}) {
    this.id = data.id || null
    this.status = data.status || null
    this.property_id = data.property_id || null
    this.property = data.property || null
    this.user_id_from = data.user_id_from || null
    this.user_from = data.userFrom || null
    this.user_id_to = data.user_id_to || null
    this.user_to = data.userTo || null
    this.office_id_from = data.office_id_from || null
    this.office_from = data.officeFrom || null
    this.office_id_to = data.office_id_to || null
    this.office_to = data.officeTo || null
    this.client_id = data.client_id || null
    this.meta = data.meta || {}
    this.client = data.client || null
    this.cooperation_date = data.cooperation_date || null
  }
}
